import { firebase } from './db';
import { BLOG_ROUTE, FEATURED_ROUTE } from './strings';
// import { type } from 'os';
var slugify = require('slugify');
const uuidv5 = require('uuid/v5');
const APP_UUID_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';/// this is randomly chosen but same on app and mobile
// const he = require('he');

// export const serverApi = 'http://localhost:3000/api/';
// export const serverApi = 'http://localhost:5000/podcastapp-767c2/us-central1/';  // for dev
export const serverApi = 'https://us-central1-podcastapp-767c2.cloudfunctions.net/';
export const cdnApi = 'https://podcastapp-767c2.firebaseapp.com/';

export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/podcastapp-767c2.appspot.com/';

export const reviewsLimit = 8;
export const firestoreApi = 'https://firestore.googleapis.com/v1/projects/podcastapp-767c2/databases/(default)/documents/';

export const websiteUrl = 'https://podurama.com';
export const domainName = 'https://podurama.com/';
export const domainNameWeb = 'https://podurama.com/explore/';

export const appStoreUrl = 'https://apps.apple.com/app/apple-store/id1497491520?pt=120962850&ct=WebBlog&mt=8';
export const appStoreUrlLanding = 'https://apps.apple.com/app/apple-store/id1497491520?pt=120962850&ct=WebLanding&mt=8';
// export const playStoreUrl = 'https://play.google.com/apps/testing/com.podurama.podcast';

export const webHomeUrl = domainName + 'home';
export const supportEmail = 'support@podurama.com';
export const testflightUrl = 'https://testflight.apple.com/join/ZgoCybwh';
export const appleStoreUrl = appStoreUrl; // 'https://apps.apple.com/us/app/podurama/id1497491520';
export const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.podurama.podcast';
export const macStoreUrl = 'https://apps.apple.com/us/app/podcast-player-podurama/id1497491520?platform=mac';
export const windowsStoreUrl = 'https://www.microsoft.com/store/apps/9NNT80B3595S';

export const staticAssetsPrefix = process.env.NODE_ENV === 'production' ? '/explore' : '';
export const domainPrefix = process.env.NODE_ENV === 'production' ? '/explore' : '';

export const acceleratedAppleStoreUrl = 'https://apps.apple.com/app/id6477714480?platform=iphone';
export const acceleratedPlayStoreUrl = 'https://play.google.com/store/apps/details?id=com.podurama.books';

export const topBarPremiumHeight = '32px';

export const articles = {
    'how-to-promote-podcast': {
        'title': 'How to Promote a Podcast: 21 Podcast Marketing Strategies',
        'imageSmall': 'promotion_small.png',
        'image': 'promotion-min.png',
        metaDescription: "Learn how to promote your podcast and reach a larger audience with effective podcast marketing tactics. From leveraging social media, blogs, and reviews to optimizing search results - we'll go over it all in this informative blog post!"
    },
    'what-is-podcast': {
        'title': 'What is a podcast and how it works? A simple explanation',
        'imageSmall': 'whatIsPodcast_small.jpg',
        'image': 'whatIsPodcast.jpg',
        metaDescription: 'Learn what podcasts are, how they work, and get recommendations for some of the best podcast shows that can help get you started. Find out all about how podcasts can add entertainment value into your life!'
    },
    'how-to-listen-to-podcasts': {
        'title': 'How to listen to podcasts for Free on any device',
        'imageSmall': 'howToListen_small.jpg',
        'image': 'howToListen.jpg',
        metaDescription: 'Discover how you can listen to podcasts anywhere with these free tips and tricks. Learn all the secrets of finding, downloading, and streaming your favorite podcasts onto any device.'
    },
};

export const getBlogAcceleratedImage = (slug) => {
    if (!slug) {
        return 'default';
    }
    console.dir(slug);
    if (slug == 'best-joe-rogan-podcasts') {
        return 'joRogan';
    } else if (slug == 'best-conservative-podcasts') {
        return 'conservative';
    } else if (slug == 'best-conspiracy-podcasts') {
        return 'conspiracy';
    } else if (slug == 'best-mystery-podcasts' || slug == 'best-true-crime-podcasts') {
        return 'mystery';
    } else if (slug.includes('physics')) {
        return 'physics';
    } else if (slug.includes('sci-fi')) {
        return 'sci-fi';
    }
    return 'default';
}

export function convertToSlug(title) {
    if (title) {
        return slugify(title, {
            lower: true, remove: /[^\w\s-]/g
        });
    } else {
        return encodeURIComponent(title);
    }
}

export function compareIndex(a, b) {
    const indexA = parseInt(a.index || 0);
    const indexB = parseInt(b.index || 0);

    let comparison = 0;
    if (indexA > indexB) {
        comparison = 1;
    } else if (indexA < indexB) {
        comparison = -1;
    }
    return comparison;
}

export function compareScore(a, b) {
    const scoreA = a.score || 0;
    const scoreB = b.score || 0;

    let comparison = 0;
    if (scoreA > scoreB) {
        comparison = -1;
    } else if (scoreA < scoreB) {
        comparison = 1;
    }
    return comparison;
}


export function isLocalStoragePresent() {
    try {
        const key = "__some_random_key_27";
        window.localStorage.setItem(key, key);
        window.localStorage.removeItem(key);
        return true;
    } catch (e) {
        return false;
    }
}

const localMap = {};
export function saveLocally(key, val, expiresInHours = 24 * 3, isTemp = false) {
    if (isLocalStoragePresent() && !isTemp) {
        let now = Date.now();
        let scheduleInMillis = now + (expiresInHours * 60 * 60 * 1000);
        window.localStorage.setItem(key, val);
        window.localStorage.setItem(key + '_expiresIn', scheduleInMillis);
    } else {
        localMap[key] = val;
    }
}
export function retrieveLocally(key, isTemp = false) {
    if (isLocalStoragePresent() && !isTemp) {
        let now = Date.now();
        let expiresInMillis = localStorage.getItem(key + '_expiresIn');
        if (expiresInMillis < now) {
            removeStorage(key);
            return null;
        }
        return window.localStorage.getItem(key);
    } else {
        return localMap[key];
    }
}
function removeStorage(key) {
    try {
        localStorage.removeItem(key);
        localStorage.removeItem(key + '_expiresIn');
    } catch (e) {
        // console.log('removeStorage: Error removing key ['+ key + '] from localStorage: ' + JSON.stringify(e) );
        return false;
    }
    return true;
}

let currentPodcastData = {};
export function setCurrentPodcastData(podcastData) {
    currentPodcastData[podcastData.collectionId] = podcastData;
    return;
}
export function getCurrentPodcastData(collectionId) {
    return currentPodcastData[collectionId];
}

export function featuredAllUrl() {
    return firestoreApi + 'featured';
}
export function featuredMainUrl(slug) {
    return firestoreApi + 'featured/' + slug + '/final/article';
}
export function blogMainUrl(slug) {
    return firestoreApi + 'blog/' + slug + '/final/article';
}

export function addLinkPrefix(url) {
    return url ? (url.startsWith('http') ? url : 'https://' + url) : '';
}

export function isFeatured(route) {
    return route.startsWith(FEATURED_ROUTE);
}

export function isBlog(route) {
    return route.startsWith(BLOG_ROUTE);
}

export function isArticle(route) {
    return isFeatured(route) || isBlog(route) || route.includes('promote') || route.includes('how-to-listen') || route.includes('what-is');
}

export function podcastSlugUrl(collectionName, itunesRawId) {
    return `/podcast/${convertToSlug(cleanText(collectionName))}-i${itunesRawId}`;
}
export function podcastHrefUrl(itunesRawId) {
    return `/podcast?PzID=${itunesRawId}`;
}

export const blogAuthors = {
    '1': { 'name': 'Liz Bailey' },
    '2': { 'name': 'Ash Hall' },
    '3': { 'name': 'Jessica Taylor' },
};

export function secToDateDay(seconds) {
    return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
        // second: '2-digit', // just for now testing
        // }).format(new Date(podcastEpisode.pubDate))}</p>
    }).format(Date.parse(new Date(seconds * 1000).toISOString().replace(" ", "T")))
    //  new Date(seconds * 1000).toISOString();
}

export function singular(text, val) {
    if (val === 1) {
        return text;
    } else {
        return text + 's';
    }
}

export function getPodcastImg(episode) {
    if (episode && episode.artworkUrl600) {
        return episode.artworkUrl600;
    } else if (episode && episode.thumbnail) {
        return episode.thumbnail;
    } else {
        return ''; // return some default image in future.
    }
}

export function showSubscriberCount(subscribers) {
    if (!isNaN(subscribers)) {
        if (subscribers < 1000) {
            if (subscribers < 1) {
                subscribers = subscribers + 1;
            }
            // return subscribers.toString();
            return (Math.ceil(subscribers / 100) / 10).toFixed(1) + 'K';
            // return subscribers;
        } else {
            return (subscribers / 1000).toFixed(1) + 'K';
        }
    } else {
        return '0.1K';
    }
}

export function getUUID(inputString) {
    if (!inputString) {
        return;
    }
    return uuidv5(inputString, APP_UUID_NAMESPACE);
}

export function getPodcastImgSize(imgUrl, size = 'lg') {
    let sizeVal;
    switch (size) {
        case 'xxs':
            sizeVal = 20;
            break;
        case 'xs':
            sizeVal = 80;
            break;
        case 'sm':
            sizeVal = 100;
            break;
        case 'md':
            sizeVal = 160;
            break;
        case 'lg':
            sizeVal = 300;
            break;
        default:
            sizeVal = 600;
    }

    const testStr = '/600x600';
    if (imgUrl && imgUrl.includes(testStr)) {
        const str = imgUrl.replace(testStr, `/${sizeVal}x${sizeVal}`);
        return str;
    } else {
        return imgUrl;
    }

}

export function getIdFromUID(userUID) {
    return userUID.slice(-9);
}

export function ratingValue(rating, fixed = 1, type = 'str') {
    if (rating && rating > 0 && type === 'num') {
        return parseFloat(rating.toFixed(fixed));
    } else if (rating && rating > 0 && type === 'str') {
        return rating.toFixed(fixed);
    }
    if (type === 'num') {
        return 0;
    } else {
        return '-';
    }
}

export const makeCancelable = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then((val) => {
            console.log(val);
            hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
        }
        );
        promise.catch((error) => {
            console.log(error);
            hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
        }
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};

export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, source) {
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, { [key]: source[key] });
                else
                    output[key] = mergeDeep(target[key], source[key]);
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}

// export function objToArr(obj) {
//     Object.keys(obj).map(function (key) {
//         return [Number(key), obj[key]];
//     });
// }


export function secToTime(seconds, fullText = false, shortText = false) {
    if (typeof seconds === 'undefined' || seconds === 0) {
        return '--:--';
    }
    let secondsNumber = 0;
    if (typeof seconds === 'string' && seconds.includes(':')) {
        secondsNumber = 0;
        let secondsMicroSec = seconds.split('.')[0];
        let secondsList = secondsMicroSec.split(':');
        for (let i = 0; i < secondsList.length; i++) {
            // print('secondsList : ' + secondsList[i].toString());
            secondsNumber += (parseInt(secondsList[i]) || 0) *
                Math.pow(60, secondsList.length - i - 1);
            // print('secondsListNumber : ' + secondsNumber.toString());
        }

        // if (seconds.length > 4 && seconds.startsWith('00:')) {
        //     return seconds.substring(3);
        // }
        // return seconds;
    } else if (typeof seconds === 'string') {
        secondsNumber = parseInt(seconds);
    } else {
        secondsNumber = seconds;
    }
    const hrs = Math.floor(secondsNumber / 3600);
    const min = Math.floor((secondsNumber % 3600) / 60);
    const sec = Math.round(secondsNumber % 60);
    if (fullText) {
        if (shortText) {
            return (hrs > 0 ? (hrs + 'h ') : '') + doubleDigits(min) + 'm';
        } else {
            return (hrs > 0 ? (hrs + ' HR ') : '') + doubleDigits(min) + ' MINS';
        }
    } else {
        return (hrs > 0 ? (hrs + ':') : '') + doubleDigits(min) + ':' + doubleDigits(sec);
    }
}

export function secToDate(seconds) {
    return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit', // just for now testing
        // }).format(new Date(podcastEpisode.pubDate))}</p>
    }).format(Date.parse(new Date(seconds * 1000).toISOString().replace(" ", "T")))
    //  new Date(seconds * 1000).toISOString();
}

export function bytesToMB(bytes) {
    if (bytes) {
        return Math.round(bytes / Math.pow(10, 6)) + ' MB';
    }
}

export const podcastTypes = [
    {
        'id': 'All',
        'name': 'All',
        'color': '#3dbaf0',
        'icon': '',
    },
    {
        'id': 'Arts',
        'name': 'Arts',
        'color': '#5e94ff',
        'icon': 'paint-brush',
    },
    {
        'id': 'Business',
        'name': 'Business',
        'color': '#956eff',
        'icon': 'briefcase',
    },
    {
        'id': 'Comedy',
        'name': 'Comedy',
        'color': '#edc12f',
        'icon': 'theater-masks',
    },
    {
        'id': 'Education',
        'name': 'Education',
        'color': '#ff9655',
        'icon': 'book-open',
    },
    {
        'id': 'Fiction',
        'name': 'Fiction',
        'color': '#2980b9',
        'icon': 'user-astronaut',
    },
    {
        'id': 'Health-and-Fitness',
        'name': 'Health & Fitness',
        'color': '#3dbaf0',
        'icon': 'dumbbell',
    },
    {
        'id': 'History',
        'name': 'History',
        'color': '#9b59b6',
        'icon': 'landmark',
    },
    {
        'id': 'Kids-and-Family',
        'name': 'Kids & Family',
        'color': '#4C384E',
        'icon': 'baby-carriage',
    },
    {
        'id': 'Leisure',
        'name': 'Leisure',
        'color': '#ff5e79',
        'icon': 'dice',
    },
    {
        'id': 'Music',
        'name': 'Music',
        'color': '#5e94ff',
        'icon': 'music',
    },
    {
        'id': 'News',
        'name': 'News',
        'color': '#956eff',
        'icon': 'newspaper',
    },
    {
        'id': 'Religion-and-Spirituality',
        'name': 'Religion & Spirituality',
        'color': '#edc12f',
        'icon': 'praying-hands',
    },
    {
        'id': 'Science',
        'name': 'Science',
        'color': '#ff9655',
        'icon': 'flask',
    },
    {
        'id': 'Society-and-Culture',
        'name': 'Society & Culture',
        'color': '#2980b9',
        'icon': 'people-carry',
    },
    {
        'id': 'Sports',
        'name': 'Sports',
        'color': '#3dbaf0',
        'icon': 'football-ball',
    },
    {
        'id': 'Technology',
        'name': 'Technology',
        'color': '#9b59b6',
        'icon': 'rocket',
    },
    {
        'id': 'True-Crime',
        'name': 'True Crime',
        'color': '#4C384E',
        'icon': 'user-secret',
    },
    {
        'id': 'TV-and-Film',
        'name': 'TV & Film',
        'color': '#ff5e79',
        'icon': 'tv',
    }
];

export const loadUser = (userDetails) => new Promise((resolve) => {
    if (userDetails) {
        resolve({ userDetails, newData: false });
    } else {
        firebase.auth().onAuthStateChanged(user => {
            // console.dir(user);
            if (user) {
                resolve({ userDetails: user, newData: true });
            } else {
                resolve(null);
            }
        })
    }
});

export function cleanText(text) {
    if (typeof text === 'undefined') {
        return '';
    }
    // const cleanedText = text.replace(/&amp;/g, '&').replace(/&apos;/g, "'").replace(/&quot;/g, "\"");
    // return cleanedText;
    return text; // he.decode(text);
}

export function doubleDigits(val) {
    return val < 10 ? '0' + val : val;
}

export function cleanFirebaseId(str) {
    if (str) {
        const v1 = str.replace(new RegExp('//', 'g'), '__');
        const v2 = v1.replace(new RegExp('/', 'g'), '_');
        const v3 = v2.replace(new RegExp('\\.', 'g'), '___');
        return v3;
    } else {
        return '';
    }
}

export function createPodcastObj(podcastInfo) {
    return {
        collectionName: podcastInfo.collectionName || podcastInfo.trackName,
        artistName: podcastInfo.artistName,
        artworkUrl600: podcastInfo.artworkUrl600,
        collectionId: podcastInfo.collectionId,
        feedUrl: podcastInfo.feedUrl,
    }
}

// export function createPodcastObj(podcastInfo) {
//     return {
//         collectionName: podcastInfo.collectionName || podcastInfo.trackName,
//         artistName: podcastInfo.artistName,
//         artworkUrl600: podcastInfo.artworkUrl600,
//         collectionId: podcastInfo.collectionId,
//         collectionExplicitness: podcastInfo.collectionExplicitness,
//         feedUrl: podcastInfo.feedUrl,
//         genres: podcastInfo.genres,
//         primaryGenreName: podcastInfo.primaryGenreName,
//         trackCount: podcastInfo.trackCount
//     }
// }

const episodesToFetchEachTime = 10;

export const episodesPerFetch = episodesToFetchEachTime;

export function getEpisodePaginationMap(totalItemCount) {
    const episodesPerFetch = episodesToFetchEachTime; // same as that used in cloud functions.
    // console.dir(episodesPerFetch);
    let lastFew;
    let paginationMap = new Map(); // {};
    if (totalItemCount <= episodesPerFetch) {
        lastFew = totalItemCount;
        paginationMap.set(`${lastFew} - 1`, { start: lastFew, count: 'all' });
    } else {
        lastFew = totalItemCount % episodesPerFetch;
        if (lastFew <= episodesPerFetch / 2) {
            lastFew = lastFew + episodesPerFetch;
        }
        // console.dir(totalItemCount - lastFew + 1);
        paginationMap.set(`${totalItemCount} - ${totalItemCount - lastFew + 1}`, { start: 'latest' });
        let lastEpisode = totalItemCount - lastFew + 1;
        while (lastEpisode > 1) {
            let firstEpisode = lastEpisode - 1;
            lastEpisode = firstEpisode - episodesPerFetch + 1;
            paginationMap.set(`${firstEpisode} - ${lastEpisode}`, { start: firstEpisode, count: episodesPerFetch });
        }
    }
    return paginationMap;
}

export function giveNumberToEpisodes(episodes, episodeFrom, totalItemCount) {
    if (episodes) {
        let episodeFirstNum = episodeFrom == 'latest' ? totalItemCount : episodeFrom;
        for (let episode of episodes) {
            episode['appEpisodeNumber'] = episodeFirstNum;
            episodeFirstNum--;
        }
    }
    return episodes;
}

export function getFirestoreIdFromSlug(userPlaylists, playlistSlugId) {
    console.log(userPlaylists);
    console.log(playlistSlugId);
    // const index = Object.values(userPlaylists).findIndex(item => item.slug === playlistSlugId);
    // const firestoreId = Object.keys(userPlaylists)[index];
    for (let key of Object.keys(userPlaylists)) {
        if (userPlaylists[key].slug === playlistSlugId) {
            return key;
        }
    }
}

export function createEpisodeObj(episodeInfo) {
    return {
        author: episodeInfo.author || '',
        enclosure: episodeInfo.enclosure || '',
        guid: episodeInfo.guid || '',
        pubDate: episodeInfo.pubDate || '',
        thumbnail: episodeInfo.thumbnail || '',
        artworkUrl600: episodeInfo.artworkUrl600 || '',
        description: episodeInfo.description || episodeInfo.content || '',
        title: episodeInfo.title || ''
    }
}

export function sortAlphabetically(a, b) {
    return a.collectionName.localeCompare(b.collectionName);
}

export function sortAlphabeticallyName(a, b) {
    return a.name.localeCompare(b.name);
}

export function sortPubDate(a, b) {
    if (b.pubDate && a.pubDate) {
        // return b.pubDate.localeCompare(a.pubDate);
        return b.pubDate - a.pubDate;
    } else {
        return false;
    }
}

export function sortNumericallyDesc(a, b) {
    return b.dateAdded['_seconds'] - a.dateAdded['_seconds'];
}

export function sortByTimeAsc(a, b) {
    return a['ordinalityDate'] - b['ordinalityDate'];
}
export function sortByTimeDesc(a, b) {
    return b['ordinalityDate'] - a['ordinalityDate'];
}

export function sortLastModifiedDate(a, b) {
    return b.lastModifiedDate['seconds'] - a.lastModifiedDate['seconds'];
}

export function sortNumericallyLastDesc(a, b) {
    return b.lastPlayed['seconds'] - a.lastPlayed['seconds'];
}

export function sortRating(a, b) {
    return b.rating - a.rating;
}

export const authError = {
    'auth/user-not-found': 'Incorrect username or password. Please try again.',
    'auth/email-already-in-use': 'You are already registered. Please click on Forgot Password to reset your password.',
    'auth/weak-password': 'Password should be at least 6 characters'
}