// import { CloseOutlined } from '@ant-design/icons';
import React, { Component } from 'react'
// import { getIsPremiumUser } from '../../lib/fileUtils';
import { retrieveLocally, saveLocally, topBarPremiumHeight, websiteUrl } from '../../lib/utils';
import AppCustomLink from './AppCustomLink';
import PropTypes from 'prop-types';
import { maxScreenSize } from '../../lib/theme';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import { addTopPadding } from '../../actions';

class PremiumTop extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false, loading: true };
    }

    componentDidMount() {
        const hide = retrieveLocally('hidePremiumTop');
        if (!hide) {
            this.setState({ show: true });
            setTimeout(() => {
                // this.props.addTopPadding(40);
            }, 1000);
        }
        setTimeout(() => {
            this.setState({ loading: false })
        }, 1000);
    }

    onClick = (e) => {
        console.dir(e);
        this.onClickDiv();
        e.stopPropagation();
    }
    onClickDiv = () => {
        saveLocally('hidePremiumTop', true, 30 * 24);
        // this.props.addTopPadding(0);
        this.setState({ show: false });
    }
    render() {
        const { loading, show } = this.state;
        // if (loading || getIsPremiumUser() || !show) {
        // return <></>
        // }
        return (
            <div>
                <div>
                    <a href={`${websiteUrl}/premium-offer`} >
                        <div onClick={this.onClickDiv} className='offline'>
                            <h3 className='mb-0 quicksand' style={{}}>🎉 &nbsp;&nbsp;Limited Time Offer: 50% off lifetime premium across iOS, Android, web and desktop apps</h3>
                            {/* <h3 className='mb-0' style={{ paddingTop: '2px' }}>&nbsp;&nbsp;<CloseOutlined onClick={(e) => this.onClick(e)} /></h3> */}
                        </div>
                    </a>
                </div>
                {/* <Offline>
                    <div className='offline'>
                        <h4 style={{}}>You are offline. All your data may not be in sync.</h4>
                        
                    </div>
                </Offline> */}
                <style jsx>
                    {`
                    .offline {
                        position: fixed;
                        top: 0;
                        z-index: 1000;
                        background: #921948; // rgb(13,33,53);// #956eff;
                        background: #611030; // rgb(13,33,53);// #956eff;
                        // background-image: linear-gradient(90deg, #611030, #300818);
                        width: 100vw;
                        // max-width: 400px;
                        // margin: auto;
                        height: ${topBarPremiumHeight};
                        // padding: 0.5em;
                        text-align: center;
                        // border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white !important;
                        // margin-left: 20vw;
                        left: 50vw;
                        transform: translate(-50%, 0);
                        // padding: 24px 4px;
                        cursor: pointer;
                    }
                    h3 {
                        color: white !important;
                        font-family: 'Didact Gothic', sans-serif;
                        font-size: 14px;
                        margin-bottom: 0;
                    }
                    @media ${maxScreenSize.tablet} {
                        h3 {
                            font-size: 12px;
                        }
                    }
                `}
                </style>
            </div>
        )
    }
}

// PremiumTop.propTypes = {
//     // addTopPadding: PropTypes.func,
// }

// //   const mapStateToProps = (state) => {
// //     return {
// //       userDetails: state.userDetails,
// //       loadingState: state.loadingState,
// //       settingsState: state.settingsState,
// //       // loadingUserDetails: state.loadingState.loadingUserDetails,
// //     }
// //   }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         // addTopPadding: bindActionCreators(addTopPadding, dispatch),
//         // toggleDarkTheme: bindActionCreators(toggleDarkTheme, dispatch),
//     }
// };

// export default connect(null, mapDispatchToProps)(PremiumTop);
export default PremiumTop